import { css } from "@pigment-css/react";
export var hoverFilter = css(function (param) {
  var theme = param.theme;
  return {
    "&:after": {
      content: "none",
      display: "block",
      height: theme.vars.space[100],
      position: "absolute",
      bottom: 0,
      top: "100%",
      width: "100%"
    }
  };
});
export var hoverFilterShowSpacer = css({
  "&:after": {
    content: "' '"
  }
});