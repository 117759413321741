function _define_property(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }
  return obj;
}
import { css } from "@pigment-css/react";
import { deviceQueries } from "@stubhub/broadway-foundation";
export var baseAccolade = css(function (param) {
  var theme = param.theme;
  return _define_property({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.vars.space[100],
    width: "100%",
    padding: "".concat(theme.vars.space[50], " ").concat(theme.vars.space[150]),
    color: theme.vars.colors.neutral.textOnPrimary,
    textAlign: "center"
  }, "@media ".concat(deviceQueries.desktop), {
    padding: "".concat(theme.vars.space[100], " ").concat(theme.vars.space[200]),
    color: "var(--component-accolade-color, ".concat(theme.vars.colors.neutral.textOnPrimary, ")"),
    background: "var(--component-accolade-background, initial)"
  });
});
export var baseAccoladeTextStart = css({
  textAlign: "start"
});
export var baseAccoladeTextEnd = css({
  textAlign: "end"
});
export var accoladeText = css({
  color: "inherit !important"
});
export var accoladeTextGradient = css({
  color: "transparent !important",
  background: "var(--component-accolade-gradient, initial)",
  backgroundClip: "text"
});
export var iconWrapper = css({
  display: "flex",
  flexShrink: 0
});